import { useEffect, useState } from "react";
import { ENV, globalCurrentDate, sendRequestApi } from "../utils";
import DatePicker from "../components/home/DatePicker";
import Loading from "../components/home/Loading";
import Picture from "../components/home/Picture";

//internal and static


const Home = () => {

    useEffect(() => {
        (async() => {
            getPicture("");
        })()
    }, [])
    

    const [picture, setPicture] = useState(null);
    const [currentDate, setCurrentDate] = useState(globalCurrentDate());
    const [fechaSiguiente, setFechaSiguiente] = useState(null);
    const [fechaAnterior, setFechaAnterior] = useState(null);
    const [loading, setLoading] = useState(false);

    const getPicture = async(date) => {
        setPicture(null);
        setLoading(true);
        if(! date || String(date).trim() === ""){
            date = currentDate ? currentDate : globalCurrentDate();
        }

        setCurrentDate(date);
        console.log(date);

        const response = await sendRequestApi({
            method: 'GET',
            endpoint: ENV.ENDPOINTS.IMAGES.GET_IMAGE,
            params: {
                date,
            }
        })

        if(response && response.pic){
            let pic = null;

            if (Array.isArray(response.pic)) {
                pic = response.pic[0];
            } else {
                pic = response.pic;
            }

            const current = pic.date;
            const fechaS = response.fechaSiguiente;
            const fechaA = response.fechaAnterior;

            setPicture(pic);
            setCurrentDate(current);
            setFechaSiguiente(fechaS);
            setFechaAnterior(fechaA);

            setLoading(false);

            localStorage.setItem('apod_current_date', current)

        } else {
            alert("An error ocurred!");
        }
    }

    return (
        <div>
            <div className="container justify-content-center">
                <div className="col-md-12">
                    <div className="card text-dark bg-light mb-3">
                        <div className="card-header">
                            <h4 className="text-center">{currentDate}</h4>
                        </div>
                    </div>
                    {/* Datepicker input */}
                    <DatePicker 
                        onChangeDate={getPicture} 
                        date={currentDate} 
                    />

                    {loading && (
                        <Loading />
                    )}

                    <Picture 
                        fechaSiguiente={fechaSiguiente} 
                        fechaAnterior={fechaAnterior} 
                        picture={picture}
                        onNextOrPrevious={getPicture}
                    />
                </div>
            </div>
        </div>
    )
}

export default Home;