import './AppStyles';

import HeaderApp from './components/HeaderApp';
import FooterApp from "./components/FooterApp";
import AppNavigation from './navigations/AppNavigation';
import { AuthProvider } from './contexts';
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <HeaderApp />
        <AppNavigation/>
        <FooterApp />
        <Toaster />
      </div>
    </AuthProvider>
  );
}

export default App;
