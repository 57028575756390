const FooterApp = () => {
    return (
        <div className="container">
            <footer className="footer">
                <p>
                    &copy; <a target="_blank" rel="noreferrer" href="https://apod.nasa.gov/apod/">NASA APOD</a>
                </p>

                <p>
                    Created by <b>Cristian Galeano</b> For educational purposes
                </p>
                <br /><br />
            </footer>
        </div>
    )
}

export default FooterApp;