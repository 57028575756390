import moment from "moment";
import {toast} from "react-hot-toast";

export const BASE_PATH_API = `https://apod-nasa.apps.cristiangaleano.com`;
//export const BASE_PATH_API = `http://localhost:8000`;
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ADM_TOKEN="6a5993e1142db935d9cea69362d5a3370a39683d6709f375cfe285e866ad7d67"

export const ENV = {
    ENDPOINTS: {
        USERS: {
            GET_ME: 'users/me',
            LOGIN: 'users/login',
            REGISTER: 'users/register',
        },
        IMAGES: {
            GET_IMAGE: 'nasa',
            GET_FAVORITES: 'nasa/allFavorites',
            MARK_FAVORITE: 'nasa/markFavorite',
            REMOVE_FAVORITE: 'nasa/quitFavorite',
            DOWNLOAD: 'api/nasa/download',
        }
    }
}

export const globalCurrentDate = () => {
    let l = localStorage.getItem("apod_current_date");

    if(l !== null && l !== 'undefined' && l !== undefined && String(l).trim() !== ""){
        return l;
    }

    return moment().format('YYYY-MM-DD');
}

export const linkDownloadPicture = picture => {
    let u = `/${ENV.ENDPOINTS.IMAGES.DOWNLOAD}/:id`;
    let urlTo = u.replace(':id', picture._id);
    urlTo = BASE_PATH_API+urlTo;
    return urlTo;
}

export const sendRequestApi = async(config) => {

    let basePath = BASE_PATH_API;

    if(config.api_uri && String(config.api_uri).trim() !== ""){
        basePath += `/${config.api_uri}`;
    } else {
        basePath += '/api';
    }

    const {
        method = 'GET',
        endpoint = '',
        params = {},
        isFormData = false
    } = config

    try {

        if(endpoint && String(endpoint).trim() !== ""){
            basePath += `/${endpoint}`;
        }

        let headers = {
            'Accept': 'application/json',
        }

        if(!isFormData){
            headers['Content-Type'] = 'application/json';
        }

        if(config.authorization && String(config.authorization).trim() !== ""){
            headers["Authorization"] = `Bearer ${String(config.authorization).trim()}`;
        } else {
            const tt = getAccessToken();

            if(null !== tt && String(tt).trim() !== ""){
                headers["Authorization"] = `Bearer ${String(tt).trim()}`;
            }
        }

        let configRequest = {
            method: String(method).toUpperCase(),
            headers,
        }

        if(params) {
            if(configRequest.method === 'HEAD' || configRequest.method === 'GET'){
                const _p = new URLSearchParams(params);
                const query = _p.toString();

                basePath += (String(query).trim() !== "") 
                ? (`${String(basePath).includes('?') ? '&' : '?'}`) 
                : "";
                
                basePath += query;
            } else {
                const _keys = Object.keys(params);
                let body = {};

                _keys.map(function(item, index){
                    body[item] = params[item];
                    return null;
                })

                configRequest.body = JSON.stringify(body);
            }
        }

        const response = await fetch(basePath, configRequest);
        const res = await response.json();

        if(res && res.status === 0){
            if(res.errors && Array.isArray(res.errors) && res.errors.length > 0){
                const _errs = res.errors.map(function(item, index){
                    return String(item).trim();
                }).join('\n');

                toast.error(_errs);
            }
        }

        return res;
        
    } catch (error) {
        console.log(error);
        toast.error("Sorry, an error ocurred. Please try again.");
        return {
            status: 0,
            message: 'An error ocurred!',
            error,
        }
    }
}

export const getAccessToken = () => {
    try {

        const token = localStorage.getItem(ACCESS_TOKEN_KEY);

        if(token !== null){
            return String(token).trim();
        }

        return null;
        
    } catch (error) {
        return null;
    }
}

export const setAccessToken = (token) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    return token;
}