import * as Yup from "yup";

export const initialValues = () => {
    return {
        name: "",
        email: "",
        password: "",
    }
}

export const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string()
    .email()
    .required(),
    password: Yup.string()
    .required()
    .min(8),
});