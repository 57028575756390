const Loading = () => {
    return (
        <div className="col-md-12">
            <h3 className="text-center">
                <img src="/img/gifloading.gif" width="auto" height="auto" />
            </h3>
        </div>
    )
}

export default Loading;