import { useState } from 'react';
import {useAuth} from '../hooks';
import { Modal as BootstrapModal } from "bootstrap"

import Register from "./auth/Register";
import Login from './auth/Login';
import { Link } from 'react-router-dom';

const HeaderApp = () => {

    const { user, logout } = useAuth();
    const [showingModalRegister, setShowingModalRegister] = useState(false);
    const [showingModalLogin, setShowingModalLogin] = useState(false);

    const [refModalRegister, setRefModalRegister] = useState(null)
    const [refModalLogin, setRefModalLogin] = useState(null)

    const handleClickRegister = (e) =>{
        setShowingModalLogin(false)
        setShowingModalRegister(true);

        const _modal = new BootstrapModal(document.getElementById('registerModal'))
        setRefModalRegister(_modal);

        _modal.show();
    }

    const handleClickLogin = (e) =>{
        setShowingModalLogin(true)
        setShowingModalRegister(false);

        const _modal = new BootstrapModal(document.getElementById('loginModal'))
        setRefModalRegister(null);
        setRefModalLogin(_modal);

        _modal.show();
    }

    const onFinishRegister = () => {
        if(null !== refModalRegister){
            refModalRegister.hide();
        }
    }

    const onFinishLogin = () => {
        if(null !== refModalLogin){
            refModalLogin.hide();
        }
    }

    return (
        <>
        <div className="py-2 container-fluid">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">APOD</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerHeader" aria-controls="navbarTogglerHeader" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerHeader">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link active" aria-current="page">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/favorites" className="nav-link">
                                    Favorites
                                </Link>
                            </li>
                            <li className="nav-item">
                            </li>
                        </ul>

                        <ul className="navbar-nav ms-auto">
                            {!user && (
                                <>
                                    <li className='nav-item'>
                                        <a href='#' className="nav-link" onClick={(event) => {
                                            event.preventDefault();
                                            handleClickLogin(event);
                                        }}>
                                            Login
                                        </a>
                                    </li>
                                    <li className='nav-item'>
                                        <a href='#' className="nav-link" onClick={(event) => {
                                            event.preventDefault();
                                            handleClickRegister(event);
                                        }}>
                                            Register
                                        </a>
                                    </li>
                                </>
                            )}

                            {user && (
                                <>
                                    <li className='nav-item'>
                                        <span className="navbar-text nav-link">
                                            Hello, {user.name}
                                        </span>
                                    </li>

                                    <li className='nav-item'>
                                        <a href='#' className="nav-link" onClick={(e) => {
                                            e.preventDefault();
                                            logout();
                                        }}>
                                            Logout
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>

                    
                </div>
            </nav>
        
            <br />
            <div className="container-fluid">
                <h3 className="text-center">NASA - PICTURE OF THE DAY</h3>
            </div>
        </div>

        <Register.Modal onFinishRegister={onFinishRegister} />
        <Login.Modal onFinishLogin={onFinishLogin} />
        </>    
    )
}

export default HeaderApp;