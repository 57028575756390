import { Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import FavoritesView from "../views/FavoritesView";

const AppNavigation = (props) => {
    return (
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/favorites" element={<FavoritesView />}></Route>
            <Route path="*" element={<Home />} />
        </Routes>
    )
}

export default AppNavigation;