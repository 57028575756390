import { initialValues, validationSchema } from "./validations.form";
import { Formik, Form as FormFormik, Field, ErrorMessage } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { sendRequestApi, ADM_TOKEN, ENV } from "../../../utils";
import {useAuth} from "../../../hooks/useAuth";
import toast from "react-hot-toast";

const Form = (props) => {

    const {
        onFinishLogin = () => {}
    } = props;

    const {login} = useAuth();

    const onSubmit = async(values, actions) => {
        const _params = {
            method: 'POST',
            params: values,
            endpoint: ENV.ENDPOINTS.USERS.LOGIN,
            authorization: ADM_TOKEN,
        }

        const response = await sendRequestApi(_params);

        if(response.status === 0){
            if(response.message){
                toast.error(response.message);
            }
            return;
        } else if(response.status === 1) {
            const {
                user,
                access_token,
                refresh_token
            } = response;

            login(user, {access_token, refresh_token});
            onFinishLogin();
            toast.success(response.message || "Logged!");
        }
    }

    return (
        <Formik
            initialValues={initialValues()}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnBlur={true}
        >

            {({ setFieldValue, isSubmitting, handleSubmit }) => (
                <FormFormik className="form form-inline" onSubmit={handleSubmit}>
                    <section className="row">

                        <div className="col-sm-12 mb-3">
                            <label className="form-label mb-1">
                                <span className="text-black">Email <i className="valueRequired text-red">*</i></span>
                            </label>
                            <Field
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="user@email.com"
                            />

                            <ErrorMessage
                                component="p"
                                name="email"
                                className="text-red text-sm"
                            />
                        </div>

                        <div className="col-sm-12 mb-3">
                            <label className="form-label mb-1">
                                <span className="text-black">Password <i className="valueRequired text-red">*</i></span>
                            </label>
                            <Field
                                type="password"
                                name="password"
                                placeholder=""
                                className="form-control form-control-lg"
                            />

                            <ErrorMessage
                                component="p"
                                name="password"
                                className="text-red text-sm"
                            />
                        </div>

                        <div className="col-sm-12 text-center">
                            <button className="btn btn-success" disabled={isSubmitting} type="submit">
                                {isSubmitting ? (
                                <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                                ) : (
                                "Sign In"
                                )}
                            </button>
                        </div>
                    </section>
                </FormFormik>
            )}
        
        </Formik>
    )

}

export default Form;