import { createContext, useEffect, useState } from "react";
import { getAccessToken, setAccessToken, ENV, sendRequestApi } from "../utils";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [user, setUser] = useState(null);
    //const [user, setUser] = useState({name: "Cristian"});
    const [token, setToken] = useState(null);
    const {children} = props;

    useEffect(() => {
      
        (async() => {
            const token = getAccessToken();
            
            if(null !== token){
                try {

                    const params = {
                        endpoint: ENV.ENDPOINTS.USERS.GET_ME,
                        method: 'GET',
                    }

                    const response = await sendRequestApi(params);
                    
                    if(response.status === 1){
                        relogin(response.user, null);
                    }
                    
                } catch (error) {
                    //silence is golden
                }
            }
        })()

    }, [])

    //internal
    const relogin = (user) => {
        setUser(user)
    }

    const login = async(user, tokens) => {

        const {
            access_token
        } = tokens

        setAccessToken(access_token);
        setToken(access_token);
        setUser(user);
    }

    const logout = async() => {
        setAccessToken(null)
        setToken(null)
        setUser(null);
    }

    return <AuthContext.Provider value={{
        user,
        token,
        login,
        logout,
    }}>
        {children}
    </AuthContext.Provider >
    
}